import React from "react"
import Seo from "./../../components/seo"

import { Box } from "@mui/material"
import Page from "../../components/page"

const AuthorsInstructions = (props) => {

  return (
    <>
      <Seo title="Navodila za avtorje" />
      <Box sx = { (theme) => ({
        bgcolor: theme.palette.white,
        height: '100%'
      })}>
        <Page className={'content'}>
            <h1>Navodila za avtorje</h1>
            <p>Vsi, ki v Dialogih še niso objavljali, naj pošljejo svoje prispevke e-pošti in jim zmeraj priložijo svoje predstavitvene podatke. Jezikovno neurejenih prispevkov uredništvo ne bo upoštevalo.</p>
            <p>Avtorji razprav in strokovnih člankov lahko dobijo natančna navodila za tehnično opremljenost pri urednikih.</p>
            <p>Dialogi so vključeni v mednarodno bazo podatkov MLA International Bibliography.</p>
          </Page>
      </Box>
    </>
  )
}

export default AuthorsInstructions